.Toastify__toast {
  border-radius: unset !important;
  padding: 0px !important;
  background-color: unset !important;
}

.Toastify__toast-container {
  width: auto !important;
}

.Toastify__toast-body {
  padding: 0px !important;
}
