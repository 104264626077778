@tailwind base;
@tailwind components;
@tailwind utilities;

[data-amplify-authenticator] {
  --amplify-components-authenticator-router-border-color: theme(
    "colors.slate.200"
  );
  --amplify-components-authenticator-router-border-width: 2px;
  --amplify-components-button-primary-background-color: theme(
    "colors.primary.500"
  );
  --amplify-components-button-primary-hover-background-color: theme(
    "colors.primary.600"
  );
  --amplify-components-button-primary-active-background-color: theme(
    "colors.primary.600"
  );
  --amplify-components-button-primary-focus-background-color: theme(
    "colors.primary.600"
  );
  --amplify-components-button-link-color: theme("colors.slate.900");
  --amplify-components-button-link-hover-color: theme("colors.slate.900");

  --amplify-components-button-link-hover-background-color: white;
  --amplify-components-button-link-active-background-color: white;

  --amplify-components-button-link-active-color: theme("colors.slate.900");
  --amplify-components-button-link-focus-background-color: white;

  --amplify-components-button-primary-focus-box-shadow: 0;
  --amplify-components-button-link-focus-box-shadow: 0;
  --amplify-internal-button-focus-box-shadow: 0;

  --amplify-components-authenticator-modal-background-color: white;

  --amplify-components-authenticator-router-box-shadow: 0 0 20px
    rgba(178, 187, 208, 0.12);
  --amplify-components-button-token-hover-background-color: red;

  --amplify-components-fieldcontrol-focus-border-color: 0;
  --amplify-components-fieldcontrol-focus-box-shadow: 0;

  .amplify-button {
    --amplify-components-button-hover-background-color: white;
    --amplify-components-button-active-background-color: white;
    --amplify-components-button-hover-border-color: theme("colors.slate.900");
    --amplify-components-button-focus-border-color: 0;
    --amplify-components-button-focus-box-shadow: 0;
  }
}

[data-amplify-authenticator] [data-amplify-router] {
  @apply rounded-xl;
}
