@import "amplify";
@import "toast";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --header-height: 6.25rem;
  --sidebar-width: 240px;
  --footer-height: 7rem;
}

body {
  margin: 0;
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;

  @apply text-grey-600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.component-pages {
  padding: 1rem 10rem;
  > div {
    margin-bottom: 1rem;
  }
}

@layer components {
  .triangle {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 8px 10px 8px;
    border-color: transparent transparent #2b323e transparent;
    left: calc(50% - 5px);
    position: absolute;
    top: -10px;
    transform: rotate(0deg);
  }

  .body-1 {
    font-size: 17px;
    line-height: 117%;
  }
  .body-2 {
    font-size: 16px;
    line-height: 117%;
  }
  .body-3 {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.3px;
    font-weight: 500;
  }
  .body-4 {
    font-size: 14px;
    line-height: 20px;
  }

  .form-input {
    @apply w-full px-4 py-4 rounded-xl bg-white border border-grey-100 hover:border-grey-300 focus:border-2;
    box-shadow:
      0px 4px 4px 0px rgba(245, 242, 242, 0.25),
      0px 2px 10px 0px rgba(225, 225, 225, 0.08);
  }

  .button {
    @apply flex py-3 px-4 items-center justify-center font-bold rounded-xl transition-all gap-2;

    &[class*="device_select"] {
      @apply text-sm border-gray-300 bg-white border;
      &:hover {
        @apply border-primary-500;
      }
      &[aria-expanded="true"] {
        @apply border;
      }
    }
  }

  .scrollbar-background-white {
    &::-webkit-scrollbar {
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 14px;
      border: 3px solid white;
    }
  }

  .search-input-remove-x {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }
  .input-number-remove-quantity {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .blocked-filters {
    @apply pointer-events-none opacity-40;
  }

  .query-data-table-container {
    @apply px-9 py-8 h-auto flex flex-col grow;
  }
}
